<template>
    <div class="home">
        <BackOnTop url="/dm/" />
        <h2>線上DM</h2>
        <div
            style="
                width: calc(500px * 0.7);
                height: calc(707px * 0.7);
                overflow: scroll;
                margin: 0 auto;
            "
        >
            <div
                class="turnClass"
                @touchstart="handleTouchStart"
                @touchend="handleTouchEnd"
                @touchmove="handleTouchMove"
            >
                <div id="flipbook">
                    <div
                        v-for="item in imgUrl"
                        :key="item.index"
                        :style="`background:url(${item.imgUrl});background-repeat:round;`"
                    ></div>
                </div>
            </div>
        </div>
        <div class="flip-control">
            <div id="prev" class="arrow">
                <img v-if="is_prev" src="../../assets/images/prev_active.png" style="width: 28px" />
                <img
                    v-else
                    src="../../assets/images/prev_inactive.png"
                    style="width: 28px"
                />
            </div>
            <div
                style="margin-left: 40px; padding: 2px 8px"
                @click="handleZoomout()"
            >
                <img
                    src="../../assets/images/dm_zoom_out.png"
                    style="width: 24px"
                />
            </div>
            <div
                style="
                    padding: 8.5px 14px;
                    font-size: 14px;
                    line-height: 16.8px;
                "
            >
                頁碼：{{ pageIndex }} / {{ totalPages }}
            </div>
            <div
                style="margin-right: 40px; padding: 2px 8px"
                @click="handleZoomin()"
            >
                <img
                    src="../../assets/images/dm_zoom_in.png"
                    style="width: 24px"
                />
            </div>
            <div id="next" class="arrow">
                <img v-if="is_next" src="../../assets/images/next_active.png" style="width: 28px" />
                <img
                    v-else
                    src="../../assets/images/next_inactive.png"
                    style="width: 28px"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
// eslint-disable-next-line no-unused-vars
import turn from '@/utils/turn.js';
import { getDmList } from '@/apis/api.js';
import $ from 'jquery';

export default {
    name: 'DmIndex',
    components: { BackOnTop },
    props: {},
    data: () => ({
        dmList: [],
        imgUrl: [],
        prev_color: 'grey',
        next_color: '#ea5523',
        pageIndex: 1,
        touchStartX: 0,
        totalPages: 0,
        startDistance: 0,
        is_prev: false,
        is_next: true
    }),
    beforeCreate() {
        // this.$store.state.isLoading = true;
    },
    mounted() {
        const parent = this;
        getDmList().then(function (rsp) {
            console.log(rsp);
            for (let i in rsp) {
                if (rsp[i].id == parent.$route.query.id) {
                    rsp[i].dm_items.map(function (obj) {
                        parent.imgUrl.push({
                            imgUrl: obj.url,
                            index: obj.sort,
                        });
                    });
                    parent.totalPages = parent.imgUrl.length;
                    parent.onTurn();
                    parent.$store.state.isLoading = false;
                }
            }
        });
    },
    methods: {
        handleZoomin() {
            // $('.turnClass').width($('.turnClass').width() + 50)
            // $('.turnClass').height($('.turnClass').height() + 71)
            // $('#flipbook').turn("size", $('.turnClass').width(), $('.turnClass').height());
            // $('#flipbook').zoom("zoomIn", this.position)
            let prev_zoom = $('#flipbook').turn('zoom');
            let zoom = prev_zoom + 0.5;
            $('.turnClass').width(500 * 0.7 * zoom);

            $('#flipbook').turn('zoom', zoom, this.position);
        },
        handleZoomout() {
            let prev_zoom = $('#flipbook').turn('zoom');
            let zoom = prev_zoom - 0.5;
            if (zoom < 1) {
                zoom = 1;
            }
            $('.turnClass').height(707 * 0.7 * zoom);

            $('#flipbook').turn('zoom', zoom, this.position);
            $('.turnClass').width(500 * 0.7 * zoom);

            // $('.turnClass').width($('.turnClass').width() - 50)
            // $('.turnClass').height($('.turnClass').height() - 71)
            // $('#flipbook').turn("size", $('.turnClass').width(), $('.turnClass').height());
        },
        handleTouchMove(e) {
            console.log(e);
            /*
            e.preventDefault();
            if(e.touches.length == 2){
                const one = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                };
                const two = {
                    x: e.touches[1].clientX,
                    y: e.touches[1].clientY
                };
                const distance = Math.sqrt(Math.pow(one.x - two.x, 2) + Math.pow(one.y - two.y, 2));
                const zoom = distance / this.startDistance;
                console.log(zoom)
                // $('.turnClass').height(707*0.7*zoom)
                if(zoom > 1){
                    $('.turnClass').width(500*0.7*zoom)
                    $('#flipbook').turn("zoom", zoom, this.position)
                } else {
                    $('#flipbook').turn("zoom", 1, this.position)
                    $('.turnClass').width(500*0.7)

                }
            }
            */
        },
        handleTouchEnd(e) {
            console.log(e);
            e.preventDefault();
            if (
                e.changedTouches.length == 1 &&
                window.visualViewport.scale == 1 &&
                $('#flipbook').turn('zoom') == 1
            ) {
                if (e.changedTouches[0].clientX - this.touchStartX > 50) {
                    $('#flipbook').turn('previous');
                } else if (
                    e.changedTouches[0].clientX - this.touchStartX <
                    -50
                ) {
                    $('#flipbook').turn('next');
                }
            }
        },
        handleTouchStart(e) {
            console.log(e);
            // e.preventDefault();
            if (e.touches.length == 1) {
                this.touchStartX = e.touches[0].clientX;
            }
            /*
            else if(e.touches.length == 2){
                const one = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                };
                const two = {
                    x: e.touches[1].clientX,
                    y: e.touches[1].clientY
                };
                const center = {
                    x: (one.x + two.x) / 2,
                    y: (one.y + two.y) / 2
                }
                this.position = center;
                const distance = Math.sqrt(Math.pow(one.x - two.x, 2) + Math.pow(one.y - two.y, 2));
                if(!this.startDistance) this.startDistance = distance;
            }
            */
        },
        onTurn() {
            const parent = this;
            this.$nextTick(() => {
                let oTurn = $('#flipbook').turn({
                    // autoCenter: true,
                    height: 707 * 0.7,
                    width: 500 * 0.7,
                });
                console.log(oTurn);
                oTurn.turn('display', 'single');
                $('#prev').click(function (e) {
                    e.preventDefault();
                    oTurn.turn('previous');
                });

                $('#next').click(function (e) {
                    e.preventDefault();
                    oTurn.turn('next');
                });

                $('#flipbook').bind('turning', function (event, page, view) {
                    console.log('page' + page, view); //翻完後要做的事情 例如寫入頁數
                    parent.pageIndex = page;
                    if (page == 1) {
                        parent.is_prev = false;
                        parent.is_next = true;
                    } else if (page == $('#flipbook').turn('pages')) {
                        parent.next_color = 'grey';
                        parent.is_prev = true;
                        parent.is_next = false;
                    } else {
                        parent.is_prev = true;
                        parent.is_next = true;
                    }
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
div.flip-control {
    // width: 400px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

div.flip-control a {
    margin-left: 10px;
}
h2 {
    color: $color_main_first;
    font-size: 26px;
    font-weight: bold;
    padding: 12px 16px;
    line-height: 1;
    text-align: left;
}
.arrow {
    // width: 7%;
    // position: absolute;
    // margin-top: 60px;
    cursor: pointer;
    // position: absolute;
    // top: 50%;
    // transform: translateY(-100%);
    z-index: 10;
}
* {
    margin: 0;
    padding: 0;
}
.home {
    //   width: 100vw;
    //   height: 100vh;
    .turnClass {
        display: flex;
        margin: 0 auto;
        height: calc(707px * 0.7);
        width: calc(500px * 0.7);
        // padding: calc((100vh - 646px)/2) 0;
        overflow-y: scroll;
        overflow-x: scroll;
    }
}
</style>
